'use client'

import { Home } from '@styled-icons/fluentui-system-filled/Home'
import { usePathname } from 'next/navigation'
import React from 'react'
import { createContext, useContext, useState } from 'react'
import { User } from '~/lib/generated/graphql'
import { navBuilder } from '~/lib/nav/nav-builder'
import { ActiveModal, DCPage, DCSection, initialNavItems, NavContext, TopNavItem } from '~/lib/types/appContext'
import { EntityType } from '~/lib/types/types'

export interface Crumb {
  text: string
  link: string
}
export type OnSaveAction = { action: () => void }

export interface SigningContext {
  accessToken: string
  baseUrl: string
  accountId: string
}

export interface AppContextProps {
  user?: User
  permissions: string[]
  activeTopItem: TopNavItem
  activeHelpTopic: string
  crumbs: Crumb[]
  activePage: DCPage
  activeSection: DCSection
  googleMapsAPIKey: string
  signing: SigningContext
  nav: NavContext
}

export type AppContextType = {
  context: AppContextProps
  setContext: (context: AppContextProps) => void
}

export const AppContext = createContext<AppContextType | null>(null)

export default function AppContextProvider({ children }: { children: React.ReactNode }) {
  const path = usePathname()
  const [context, setContext] = useState<AppContextProps>({
    permissions: ['READ', 'WRITE'],
    activeTopItem: 'dashboard',
    crumbs: [],
    activeHelpTopic: '',
    activePage: 'none',
    activeSection: 'none',
    googleMapsAPIKey: '',
    signing: {
      accessToken: '',
      baseUrl: '',
      accountId: ''
    },
    nav: navBuilder(path)
  })

  return (
    <AppContext.Provider value={{ context, setContext }}>
      <React.Fragment>{children}</React.Fragment>
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext) as AppContextType

'use client'
import { Avatar, Box, Divider, Drawer, Flex, Group, Image, Menu, Text, TextInput } from '@mantine/core'
import { useParams, usePathname } from 'next/navigation'
import Link from 'next/link'
import styles from './layout.module.css'
import { ThreeBars } from '@styled-icons/octicons'
import { signOut, useSession } from 'next-auth/react'
import { Beaker, ChevronDown, GridDots, Home, Search, Settings } from '@styled-icons/fluentui-system-filled'
import { useDisclosure } from '@mantine/hooks'
import DataCoalitionsLogoNoText from '~/components/svg/DataCoalitionsLogo-notext'
import DCMenu from './DCMenu'
import AppTray from './AppTray'
import { ListStars } from '@styled-icons/bootstrap'
import { useAppContext } from '~/components/wrapper/appContextProvider'
import MenuItem from './MenuItem'

const logo = 'https://flussodevstorage.blob.core.windows.net/flussodc/public/logotype.small.png'

export default function AppLayout({ children }: { children: React.ReactNode }) {
  const [appMenuOpen, appMenuHandlers] = useDisclosure(false)
  const [appsTrayOpen, appsTrayHandlers] = useDisclosure(false)
  const { data: session } = useSession()
  const { context } = useAppContext()
  const path = usePathname()

  const toggleSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    const appContainer = document.getElementById('appContainer')
    appContainer?.classList.toggle(styles.closedState)

    const sidebar = document.getElementById('sidebar')
    sidebar?.classList.toggle(styles.closedState)

    const contentContainer = document.getElementById('contentContainer')
    contentContainer?.classList.toggle(styles.closedState)

    document.getElementById('togglebtn')?.classList.toggle(styles.rotate)

    if (sidebar) {
      Array.from(sidebar.getElementsByClassName(styles.show)).forEach((ul) => {
        ul.classList.remove(styles.show)
        ul.previousElementSibling?.classList.remove(styles.rotate)
      })
    }
  }

  const toggleSubMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.currentTarget.nextElementSibling?.classList.toggle(styles.show)
    e.currentTarget.classList.toggle(styles.rotate)

    if (document.getElementById('sidebar')?.classList.contains(styles.closedState)) {
      document.getElementById('sidebar')?.classList.toggle(styles.closedState)
      document.getElementById('togglebtn')?.classList.toggle(styles.rotate)
    }
  }

  return (
    <main
      className={`${styles.appContainer} ${styles.closedState} ${path.startsWith('/links') ? styles.public : ''}`}
      id="appContainer"
    >
      <header className={`${styles.appHeader} ${path.startsWith('/links') ? styles.public : ''}`}>
        <Group justify="space-between">
          <Group align="center" gap={26}>
            {!path.startsWith('/links') ? (
              <Box onClick={toggleSidebar} role="button" className={styles.toggleBurger}>
                <ThreeBars size={24} color="white" />
              </Box>
            ) : null}
            <Box w="7.7rem">
              <Link href="/" passHref legacyBehavior>
                <Image src={logo} alt="Flusso logo" style={{ cursor: 'pointer' }} />
              </Link>
            </Box>
          </Group>
          <Group gap={0}>
            {!path.startsWith('/links') ? (
              <>
                <TextInput
                  type="search"
                  placeholder="Search"
                  styles={{
                    root: {
                      height: '100%'
                    },
                    input: {
                      backgroundColor: 'transparent',
                      borderRadius: '20px',
                      borderColor: 'var(--mantine-color-gray-5)',
                      padding: '0.5rem 1rem',
                      width: '30rem',
                      color: 'white'
                    }
                  }}
                />
                <Box className={styles.searchIconContainer}>
                  <Search style={{ cursor: 'pointer' }} size={24} color="var(--mantine-color-gray-5)" />
                </Box>
              </>
            ) : null}
          </Group>
          <Group align="center">
            {session?.user !== null && session?.user !== undefined ? (
              <>
                <Box role="button" style={{ cursor: 'pointer' }}>
                  <GridDots color="white" size={32} onClick={appsTrayHandlers.toggle} />
                </Box>

                <Group className={styles.userOptionsContainer} gap={4}>
                  <Box role="button" style={{ cursor: 'pointer', paddingTop: '8px' }}>
                    <DataCoalitionsLogoNoText width={64} onClick={appMenuHandlers.open} />
                  </Box>
                  <Menu shadow="md">
                    <Menu.Target>
                      <Avatar
                        style={{ cursor: 'pointer' }}
                        src={session.user?.image}
                        size="md"
                        radius="xl"
                        alt={session.user?.name ?? 'unknown user'}
                      />
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>{session.user?.name}</Menu.Label>
                      <Menu.Item onClick={() => signOut()}>Sign Out</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
              </>
            ) : null}
            <AppTray onCloseAction={appsTrayHandlers.close} isOpen={appsTrayOpen} />
            <Drawer
              position="right"
              size="lg"
              opened={appMenuOpen}
              onClose={appMenuHandlers.close}
              title="Data Coalitions"
              transitionProps={{
                transition: 'slide-left',
                duration: 200,
                timingFunction: 'linear'
              }}
            >
              <DCMenu onCloseAction={appMenuHandlers.close} />
            </Drawer>
          </Group>
        </Group>
      </header>

      <nav className={`${styles.menu} ${styles.closedState} ${path.startsWith('/links') ? styles.public : ''}`} id="sidebar">
        <section className={styles.navContainer}>
          <ul>
            {context?.nav.homeItem ? (
              <>
                <MenuItem menuItem={context.nav.homeItem} />
                <Divider
                  classNames={{
                    root: styles.menuSeparator,
                    label: styles.menuSeparator
                  }}
                  color="var(--mantine-color-gray-0)"
                />
              </>
            ) : null}
            {context?.nav.menuItems.map((menuItem, idx) => (
              <div key={`menuitem_top_${idx}`}>
                {menuItem.kind === 'standard' ? <MenuItem menuItem={menuItem} /> : null}
                {menuItem.kind === 'dash' ? (
                  <Divider
                    classNames={{
                      root: styles.menuSeparator,
                      label: styles.menuSeparator
                    }}
                    color="var(--mantine-color-gray-0)"
                  />
                ) : null}
              </div>
            ))}
          </ul>
          <ul>
            {(context?.nav.bottomMenuItems.length ?? 0) > 0 ? (
              <Divider
                classNames={{
                  root: styles.menuSeparator,
                  label: styles.menuSeparator
                }}
                color="var(--mantine-color-gray-0)"
              />
            ) : null}
            {context?.nav.bottomMenuItems.map((menuItem, idx) =>
              menuItem.kind === 'standard' ? (
                <MenuItem key={`bottom_${idx}_${menuItem.title}`} menuItem={menuItem} />
              ) : (
                <Divider
                  key={`dash_${idx}`}
                  classNames={{
                    root: styles.menuSeparator,
                    label: styles.menuSeparator
                  }}
                  color="var(--mantine-color-gray-0)"
                />
              )
            )}
          </ul>
        </section>
      </nav>

      <Flex className={`${styles.contentContainer} ${styles.closedState}`} id="contentContainer">
        {children}
      </Flex>
    </main>
  )
}

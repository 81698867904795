import { baseParticipateNavItems } from '~/app/apps/participate/navtree.participate'
import { NavContext, NavMenuItem } from '../types/appContext'
import { DocumentOnePage, Home, Payment, People } from '@styled-icons/fluentui-system-filled'
import { Scale } from '@styled-icons/material'
import { STANDARD_ICONS } from '~/components/controls/icons/StandardIcon'
import { Database, Info } from '@styled-icons/entypo'
import { ListStars } from '@styled-icons/bootstrap'

const dash: NavMenuItem = { kind: 'dash' }

export function navBuilder(path: string): NavContext {
  const isPaticipate = !!path.match(/\/apps\/participate/)
  const isResearchProject = !!path.match(/\/apps\/participate\/projects\/[^/]+/)

  let homeItem: NavMenuItem = {
    kind: 'standard',
    title: 'Home',
    Icon: Home,
    link: '/'
  }
  let topMenuItems: NavMenuItem[] = [...(isPaticipate ? baseParticipateNavItems.menuItems : [])]
  let bottomMenuItems: NavMenuItem[] = [...(isPaticipate ? baseParticipateNavItems.bottomMenuItems : [])]

  if (isResearchProject) {
    let researchProjectNav = researchProjectNavBuild(path)
    researchProjectNav.menuItems.forEach((item) => topMenuItems.push(item))
    homeItem = researchProjectNav.homeItem
  }

  return {
    homeItem: homeItem,
    menuItems: topMenuItems,
    bottomMenuItems: bottomMenuItems
  }
}

function researchProjectNavBuild(path: string): NavContext {
  const projectId = path.split('/')[4]
  const baseProjectPath = `/apps/participate/projects/${projectId}`

  return {
    homeItem: {
      kind: 'standard',
      title: 'Project Home',
      Icon: Home,
      link: baseProjectPath
    },
    menuItems: [
      dash,
      {
        kind: 'subheading',
        title: 'Research Project'
      },
      {
        kind: 'standard',
        title: 'Project Info',
        link: ``,
        Icon: Info,
        subMenuItems: [
          {
            title: 'Activities',
            link: `${baseProjectPath}/activities`
          },
          {
            title: 'Ethics',
            link: `${baseProjectPath}/ethics`
          },
          {
            title: 'General',
            link: `${baseProjectPath}/details`
          },
          {
            title: 'Locations',
            link: `${baseProjectPath}/locations`
          },
          {
            title: 'Settings',
            link: `${baseProjectPath}/settings`
          },
          {
            title: 'Staff',
            link: `${baseProjectPath}/staff`
          }
        ]
      },

      {
        kind: 'standard',
        title: 'Participants',
        link: `${baseProjectPath}/participants`,
        Icon: People,
        subMenuItems: [
          {
            title: 'Bookings',
            link: `${baseProjectPath}/bookings`
          },
          {
            title: 'Consent Form',
            link: `${baseProjectPath}/survey-builder`
          },
          {
            title: 'Participants',
            link: `${baseProjectPath}/participants`
          },

          {
            title: 'PLS',
            link: `${baseProjectPath}/pls`
          },
          {
            title: 'Surveys',
            link: `${baseProjectPath}/surveys`
          }
        ]
      },
      {
        kind: 'standard',
        title: 'Payments',
        link: `${baseProjectPath}/payments`,
        Icon: Payment,
        subMenuItems: [
          {
            title: 'Payment Model',
            link: `${baseProjectPath}/payments/payment-model`
          },
          {
            title: 'Register Payments',
            link: `#`
          },
          {
            title: 'Reporting',
            link: `#`
          }
        ]
      },
      {
        kind: 'standard',
        title: 'Surveys',
        link: `${baseProjectPath}/surveys`,
        Icon: ListStars,
        subMenuItems: [
          {
            title: 'Designer',
            link: `${baseProjectPath}/surveys`
          },
          {
            title: 'Responses',
            link: `#`
          }
        ]
      },
      {
        kind: 'standard',
        title: 'Data',
        link: `${baseProjectPath}/participants/data`,
        Icon: Database,
        subMenuItems: [
          {
            title: 'Confidentiality',
            link: `${baseProjectPath}/confidentiality`
          },
          {
            title: 'Data Management',
            link: `${baseProjectPath}/data-management`
          }
        ]
      }
    ],
    bottomMenuItems: []
  }
}
// Compare this snippet from Users/benjaminszymkow/repos/flussomono/apps/dc/app/apps/participate/navtree.participate.ts:

'use client'
import React from 'react'
import { APIProvider } from '@vis.gl/react-google-maps'

export default function NavProvider({ children, apiKey }: { children: React.ReactNode; apiKey: string }) {
  console.log('Apikey ', apiKey)
  return (
    <APIProvider apiKey={apiKey} libraries={['core']}>
      {children}
    </APIProvider>
  )
}

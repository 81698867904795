import { Institution } from '@styled-icons/boxicons-solid'
import { Beaker, Building, ClipboardBulletListLtr, Home, Ribbon, Settings } from '@styled-icons/fluentui-system-filled'
import { NavContext, NavMenuItem } from '~/lib/types/appContext'

export const baseParticipateNavItems: NavContext = {
  homeItem: {
    kind: 'standard',
    title: 'Home',
    Icon: Home,
    link: '/apps/participate'
  },
  menuItems: [
    {
      kind: 'subheading',
      title: 'Research Organization'
    },
    {
      kind: 'standard',
      title: 'Projects',
      link: `/apps/participate/projects`,
      Icon: Beaker
    },
    {
      kind: 'standard',
      title: 'Programs',
      link: `/apps/participate/programs`,
      Icon: ClipboardBulletListLtr
    },
    {
      kind: 'standard',
      title: 'Organization',
      link: `/apps/participate/organization`,
      Icon: Building,

      subMenuItems: [
        { title: 'Committees', link: '/apps/participate/committees' },
        { title: 'Insights', link: '/apps/participate/insights' },
        { title: 'Policies', link: '/apps/participate/policies' }
      ]
    },

    {
      kind: 'standard',
      title: 'Standards',
      link: `/apps/participate/standards`,
      Icon: Ribbon,

      subMenuItems: [
        { title: 'Activities', link: '/apps/participate/standards/activities' },
        { title: 'Attributes', link: '/apps/participate/standards/attributes' },
        { title: 'Roles', link: '/apps/participate/standards/roles' },
        { title: 'Third Parties', link: '/apps/participate/standards/third-parties' }
      ]
    }
  ],
  bottomMenuItems: [
    {
      kind: 'standard',
      title: 'Settings',
      link: `/apps/participate/settings`,
      Icon: Settings
    }
  ]
}

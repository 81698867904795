'use client'
import { NavMenuItem } from '~/lib/types/appContext'
import styles from './layout.module.css'
import Link from 'next/link'
import { ChevronDown } from '@styled-icons/fluentui-system-filled/ChevronDown'
import { usePathname } from 'next/navigation'
import { Tooltip } from '@mantine/core'

type MenuItemProps = {
  menuItem: NavMenuItem
}

export default function MenuItem({ menuItem }: MenuItemProps) {
  const path = usePathname()
  const toggleSubMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.currentTarget.nextElementSibling?.classList.toggle(styles.show)
    e.currentTarget.classList.toggle(styles.rotate)

    if (document.getElementById('sidebar')?.classList.contains(styles.closedState)) {
      document.getElementById('sidebar')?.classList.toggle(styles.closedState)
      document.getElementById('togglebtn')?.classList.toggle(styles.rotate)
      const appContainer = document.getElementById('appContainer')
      appContainer?.classList.toggle(styles.closedState)

      const contentContainer = document.getElementById('contentContainer')
      contentContainer?.classList.toggle(styles.closedState)
    }
  }

  return (
    <>
      {menuItem.kind === 'standard' ? (
        <li className={`${path === menuItem.link ? styles.active : ''}`}>
          {menuItem.subMenuItems && menuItem.subMenuItems?.length > 0 ? (
            <>
              <Tooltip label={menuItem.title}>
                <button className={styles.dropdownbtn} onClick={toggleSubMenu}>
                  <menuItem.Icon size={24} color="white" />
                  <span>{menuItem.title}</span>
                  <ChevronDown size={20} color="white" />
                </button>
              </Tooltip>
              <ul className={styles.submenu}>
                <div>
                  {menuItem.subMenuItems?.map((item, index) => (
                    <li
                      key={`submenu_${menuItem.title}_${item.title}_${index}`}
                      className={item.link === path ? styles.active : ''}
                    >
                      <Link href={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </div>
              </ul>
            </>
          ) : (
            <>
              {menuItem.kind === 'standard' ? (
                <Tooltip label={menuItem.title}>
                  <Link href={menuItem.link}>
                    <menuItem.Icon size={24} color="white" />
                    <span>{menuItem.title}</span>
                  </Link>
                </Tooltip>
              ) : null}
            </>
          )}
        </li>
      ) : null}
    </>
  )
}

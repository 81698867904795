'use client'

import { Flex, Stack, Title, useMantineTheme } from '@mantine/core'
import { useParams } from 'next/navigation'
import { Home, TextDocumentInverted } from '@styled-icons/entypo'
import { Database } from '@styled-icons/entypo'
import {
  DocumentBulletList,
  Handshake,
  PeopleCheckmark,
  PeopleTeam,
  PuzzlePiece,
  Settings
} from '@styled-icons/fluentui-system-filled'
import { AccountTree, AdminPanelSettings, BusinessCenter, Groups, RememberMe } from '@styled-icons/material'
import { Business, PackageIcon, ShoppingBag, UserPlus } from '@styled-icons/boxicons-solid'
import { User, Users } from '@styled-icons/heroicons-solid'
import { FilePaper2, Survey } from '@styled-icons/remix-fill'
import { Box as BoxIcon, Building, CurrencyDollar, FileTextFill, Key, PaletteFill } from '@styled-icons/bootstrap'
import { LineGraph } from '@styled-icons/entypo'
import React from 'react'
import {
  assertCoalitionPermission,
  assertGlobalPermission,
  assertOrganizationPermission
} from '../lib/security/permissionHelpers'
import MenuItem from '../components/layout/MenuItem'
import { Law } from '@styled-icons/octicons'
import { useCurrentUser } from '../lib/security/useCurrentUser'
import { Blueprint } from '@styled-icons/simple-icons'
import { GlobalPermission } from '../lib/security/permissions'
import styles from './styles.module.css'
import { Support } from '@styled-icons/boxicons-regular'

const iconSize = '3rem'

type DCMenuProps = {
  onCloseAction: () => void
}

export default function DCMenu({ onCloseAction }: DCMenuProps) {
  const { currentUser } = useCurrentUser()
  const { coalitionId } = useParams()

  const theme = useMantineTheme()

  return (
    <Stack>
      <Title order={3}>Menu</Title>
      <Stack gap="md">
        {typeof coalitionId === 'string' ? (
          <Stack variant="shadow">
            <Title order={3}>Coalition</Title>
            <Flex gap={28} wrap="wrap">
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Home"
                to={`/coalition/${coalitionId}`}
                Icon={Home}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Charter"
                to={`/coalition/${coalitionId}/charter`}
                Icon={FilePaper2}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Context"
                to={`/coalition/${coalitionId}/context`}
                Icon={PuzzlePiece}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Data"
                to={`/coalition/${coalitionId}/data`}
                Icon={Database}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Contracts"
                to={`/coalition/${coalitionId}/contract`}
                Icon={FileTextFill}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Insights"
                to={`/coalition/${coalitionId}/visualize/jurisdictional`}
                Icon={LineGraph}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Forms"
                to={`/coalition/${coalitionId}/forms`}
                Icon={DocumentBulletList}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              {assertCoalitionPermission(coalitionId as string, 'COALITION_ADMIN', currentUser) ? (
                <MenuItem
                  onCloseAction={onCloseAction}
                  color={'var(--mantine-color-brand-5)'}
                  size={iconSize}
                  text="Admin"
                  to={`/coalition/${coalitionId}/admin`}
                  Icon={AdminPanelSettings}
                  style={{ fill: 'url(#brand-gradient)' }}
                />
              ) : null}
            </Flex>
          </Stack>
        ) : null}
        <Stack variant="shadow">
          <Title order={3}>User</Title>
          <Flex gap="xl" wrap="wrap">
            <MenuItem
              onCloseAction={onCloseAction}
              color={'var(--mantine-color-brand-5)'}
              size={iconSize}
              text="Profile"
              to={`/settings/user/profile`}
              Icon={User}
              style={{ fill: 'url(#brand-gradient)' }}
            />
            <MenuItem
              onCloseAction={onCloseAction}
              color={'var(--mantine-color-brand-5)'}
              size={iconSize}
              text="Appearance"
              to={`/`}
              Icon={PaletteFill}
              style={{ fill: 'var(--mantine-color-concrete-3)' }}
              disabled
            />
            <MenuItem
              onCloseAction={onCloseAction}
              color={'var(--mantine-color-brand-5)'}
              size={iconSize}
              text="Support"
              to={`/support`}
              Icon={Support}
              style={{ fill: 'url(#brand-gradient)' }}
            />
          </Flex>
        </Stack>
        {assertGlobalPermission('GLOBAL_ADMIN', currentUser) ||
        (currentUser &&
          currentUser.primaryOrganization &&
          assertOrganizationPermission(currentUser.primaryOrganization.id, 'ORG_VIEW')) ? (
          <Stack variant="shadow">
            <Title order={3}>Organisation</Title>
            <Flex gap="xl" wrap="wrap">
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Profile"
                to={`/settings/organization/${currentUser?.primaryOrganization?.id}/profile`}
                Icon={Business}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Users"
                to={`/settings/organization/${currentUser?.primaryOrganization?.id}/users`}
                Icon={Users}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="API Keys"
                to={`/settings/organization/${currentUser?.primaryOrganization?.id}/keys`}
                Icon={Key}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Business Units"
                to={`/settings/business-units`}
                Icon={AccountTree}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Invoices"
                to={`/settings/invoices`}
                Icon={CurrencyDollar}
                style={{ fill: 'var(--mantine-color-concrete-3)' }}
                disabled
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Members"
                to={`/settings/organization/${currentUser?.primaryOrganization?.id}/members`}
                Icon={RememberMe}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Subscriptions"
                to={`/settings/organization/${currentUser?.primaryOrganization?.id}/subscriptions`}
                Icon={ShoppingBag}
                style={{ fill: 'url(#brand-gradient)' }}
              />
            </Flex>
          </Stack>
        ) : null}
        {assertGlobalPermission('GLOBAL_ADMIN', currentUser) ? (
          <Stack variant="shadow">
            <Title order={3}>Platform</Title>
            <Flex gap="xl" wrap="wrap">
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Coalitions"
                to={`/settings/platform/coalitions`}
                Icon={Groups}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Committees"
                to={`/settings/platform/committees`}
                Icon={PeopleCheckmark}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Form Templates"
                to={`/settings/platform/form-templates`}
                Icon={Survey}
                style={{ fill: 'url(#brand-gradient)' }}
              />

              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Industries"
                to={`/settings/platform/industries`}
                Icon={Building}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Industry Bodies"
                to={`/settings/platform/industry-bodies`}
                Icon={PeopleTeam}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Organizations"
                to={`/settings/platform/organizations`}
                Icon={BusinessCenter}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Packages"
                to={`/settings/platform/packages`}
                Icon={PackageIcon}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Partners"
                to={`/settings/platform/partners`}
                Icon={Handshake}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Permissions"
                to={`/settings/platform/permissions`}
                Icon={Key}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Product"
                to={`/`}
                Icon={BoxIcon}
                style={{ fill: 'var(--mantine-color-concrete-3)' }}
                disabled
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Regulations"
                to={`/settings/platform/regulations`}
                Icon={Law}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Repository Licenses"
                to={`/settings/platform/repository-licenses`}
                Icon={TextDocumentInverted}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Roles"
                to={`/settings/platform/roles`}
                Icon={UserPlus}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Settings"
                to={`/settings/platform/settings`}
                Icon={Settings}
                style={{ fill: 'url(#brand-gradient)' }}
              />
              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Templates"
                to={`/settings/platform/templates`}
                Icon={Blueprint}
                style={{ fill: 'url(#brand-gradient)' }}
              />

              <MenuItem
                onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Users"
                to={`/settings/platform/users`}
                Icon={Users}
                style={{ fill: 'url(#brand-gradient)' }}
              />

              {/* <MenuItem onCloseAction={onCloseAction}
                color={'var(--mantine-color-brand-5)'}
                size={iconSize}
                text="Preseed"
                to={`/settings/platform/preseed`}
                Icon={Users}
                style={{ fill: 'black' }}
              /> */}
            </Flex>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  )
}
